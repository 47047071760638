import React from 'react';

import { Grid, Show, Slider } from '@core';

const CardList = ({
  items,
  itemComponent: Item,
  itemsOnMobile = 1,
  itemsOnTablet = 2,
  itemsOnDesktop = 1,
  withSlider = true,
  sliderProps,
  sliderOnDesktop = false,
}) => (
  <React.Fragment>
    {withSlider ? (
      <React.Fragment>
        <Show.Mobile display="flex" width="100%">
          <Slider
            itemsPerPage={itemsOnMobile}
            items={items}
            itemComponent={Item}
            {...sliderProps}
          />
        </Show.Mobile>

        <Show.TabletOnly display="flex" width="100%">
          <Slider
            itemsPerPage={itemsOnTablet}
            items={items}
            itemComponent={Item}
            {...sliderProps}
          />
        </Show.TabletOnly>
        {sliderOnDesktop && (
          <Show.DesktopAndUp display="flex" width="100%">
            <Slider
              itemsPerPage={itemsOnDesktop}
              items={items}
              itemComponent={Item}
              {...sliderProps}
            />
          </Show.DesktopAndUp>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Show.Mobile>
          <Grid.Container>
            <Grid.Row>
              {items?.map((item, index) => (
                <Grid.Col
                  data-section-id={`Card`}
                  key={item.key || index}
                  size={12 / itemsOnMobile}
                >
                  <Item {...item} index={index} />
                </Grid.Col>
              ))}
            </Grid.Row>
          </Grid.Container>
        </Show.Mobile>

        <Show.TabletOnly>
          <Grid.Container>
            <Grid.Row>
              {items?.map((item, index) => (
                <Grid.Col
                  data-section-id={`Card`}
                  key={item.key || index}
                  size={12 / itemsOnTablet}
                >
                  <Item {...item} index={index} />
                </Grid.Col>
              ))}
            </Grid.Row>
          </Grid.Container>
        </Show.TabletOnly>
      </React.Fragment>
    )}

    {(!withSlider || !sliderOnDesktop) && (
      <Show.DesktopAndUp>
        <Grid.Container>
          <Grid.Row>
            {items?.map((item, index) => (
              <Grid.Col data-section-id={`Card`} key={item.key || index} size={12 / itemsOnDesktop}>
                <Item {...item} index={index} />
              </Grid.Col>
            ))}
          </Grid.Row>
        </Grid.Container>
      </Show.DesktopAndUp>
    )}
  </React.Fragment>
);

export default CardList;
